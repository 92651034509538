import React from 'react';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import 'twin.macro';
import Image from 'gatsby-image';
import { CompanionsQuery } from '../../generated/graphql-types';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import SEO from '../components/core/meta/SEO';

const CompanionsPage = () => {
  const companions = useStaticQuery<CompanionsQuery>(graphql`
    query Companions {
      comp1: file(relativePath: { eq: "companions/companions-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      comp2: file(relativePath: { eq: "companions/companions-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      comp3: file(relativePath: { eq: "companions/companions-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      comp4: file(relativePath: { eq: "companions/companions-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      comp5: file(relativePath: { eq: "companions/companions-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Gli accompagnatori - Accompagnamento" url="/accompagnatori" />
      <div tw="pb-20">
        <section tw="bg-gray-100 pt-20 pb-20">
          <Container>
            <Heading variant="h3" tw="text-center">
              CHI SONO I NOSTRI ACCOMPAGNATORI?
            </Heading>
            <p tw="text-paragraph text-center mb-16">
              La prima domanda che un utente o un familiare che sta prenotando
              il servizio per un proprio caro si fa è: “Ma chi saranno queste
              persone?”, “Ci potremo fidare?”. Il Team UGO si occupa
              personalmente di selezionare e formare ogni singolo collaboratore
              che entra in contatto con la società e andrà a relazionarsi con
              gli utenti, in circostanze talvolta molto delicate. Siamo
              estremamente attenti ad ogni aspetto legato a personalità,
              condotta, educazione e capacità relazionali, garantendo il
              supporto di persone che vi lasceranno piacevolmente colpiti per
              gentilezza, preparazione e discrezione.
            </p>
            <div tw="flex flex-wrap -mx-4">
              <div tw="px-4 w-full md:w-1/3 flex">
                <div tw="bg-white rounded-lg p-5 flex-1">
                  <Heading variant="h5" tw="text-primary-500 text-center">
                    1.
                    <br />
                    SELEZIONE
                  </Heading>
                  <p tw="text-paragraph text-center">
                    I nostri collaboratori vengono selezionati dal Team dopo un
                    controllo dei documenti richiesti e due colloqui (il primo
                    conoscitivo, il secondo di approfondimento e abilitazione).
                  </p>
                </div>
              </div>
              <div tw="px-4 w-full md:w-1/3 flex">
                <div tw="bg-white rounded-lg p-5 flex-1">
                  <Heading variant="h5" tw="text-primary-500 text-center">
                    2.
                    <br />
                    FORMAZIONE
                  </Heading>
                  <p tw="text-paragraph text-center">
                    Una volta abilitati al ruolo di “UGO” i collaboratori
                    seguono corsi di formazione interna e/o in collaborazione
                    con Partner in ambito di primo soccorso e gestione
                    dell’utente.
                  </p>
                </div>
              </div>
              <div tw="px-4 w-full md:w-1/3 flex">
                <div tw="bg-white rounded-lg p-5 flex-1">
                  <Heading variant="h5" tw="text-primary-500 text-center">
                    3.
                    <br />
                    MONITORAGGIO
                  </Heading>
                  <p tw="text-paragraph text-center">
                    Ogni servizio è seguito da un feedback che utente e
                    accompagnatore lasciano. Questo per controllare l’operato,
                    recepire richieste e consigli e migliorare costantemente la
                    qualità del servizio.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section tw="py-16">
          <Container>
            <Heading variant="h5" tw="text-primary-500 text-center">
              Selezione e formazione
            </Heading>
            <Heading variant="h4" tw="text-center mb-16">
              LI SELEZIONIAMO E FORMIAMO AD UNO AD UNO
            </Heading>
            <div tw="flex flex-wrap -mx-4 items-center mb-16">
              <div tw="w-full sm:w-1/2 md:w-1/3 px-4">
                <Image
                  tw="rounded-lg"
                  alt="documenti"
                  style={{ height: '250px' }}
                  fluid={companions.comp1.childImageSharp.fluid}
                />
              </div>
              <div tw="w-full sm:w-1/2 md:w-2/3 p-4">
                <Heading variant="h6">Controllo documenti richiesti </Heading>
                <p tw="text-paragraph">
                  In fase di candidatura chiediamo di inserire tutti i dati
                  personali e allegare alcuni documenti per noi imprescindibili.
                  Questi sono: fedina penale pulita; patente conseguita da più
                  di 3 anni, con almeno 20 punti e storico pulito; curriculum
                  vitae e fotografia recente. Da una prima analisi possiamo
                  verificare che queste persone si presentino bene, abbiano
                  tenuto una condotta rigorosa sino ad oggi, siano affidabili
                  alla guida e quindi in grado di gestire spostamenti in auto.
                  Non da ultimo il curriculum ci racconta la loro esperienza ed
                  attitudine rispetto la mansione in oggetto.
                </p>
              </div>
            </div>
            <div tw="flex flex-wrap -mx-4 items-center mb-16">
              <div tw="w-full sm:w-1/2 md:w-1/3 px-4">
                <Image
                  tw="rounded-lg"
                  alt="coloquio conoscitivo"
                  style={{ height: '250px' }}
                  fluid={companions.comp2.childImageSharp.fluid}
                />
              </div>
              <div tw="w-full sm:w-1/2 md:w-2/3 p-4">
                <Heading variant="h6">Colloquio conoscitivo</Heading>
                <p tw="text-paragraph">
                  Il colloquio è un momento fondamentale per avere un contatto
                  diretto con la persona, capire e studiare il suo modo di
                  relazionarsi, esprimersi, la sua attitudine caratteriale e
                  controllare che modi e atteggiamenti siano in linea con i
                  profili che cerchiamo. Inoltre il colloquio – direttamente con
                  i membri del Team – permette ai candidati di verificare la
                  serietà e la veridicità del lavoro proposto e del ruolo svolto
                  dalla piattaforma. Crediamo molto nella relazione fra le
                  persone e nel valore che apporta: è in questa fase che ne
                  poniamo le basi.
                </p>
              </div>
            </div>
            <div tw="flex flex-wrap -mx-4 items-center mb-16">
              <div tw="w-full sm:w-1/2 md:w-1/3 p-4">
                <Image
                  alt="classi di formazione"
                  tw="rounded-lg"
                  style={{ height: '250px' }}
                  fluid={companions.comp3.childImageSharp.fluid}
                />
              </div>
              <div tw="w-full sm:w-1/2 md:w-2/3 px-4">
                <Heading variant="h6">Classi di formazione dedicata</Heading>
                <p tw="text-paragraph">
                  In base alla tipologia di collaboratore e alla sua
                  formazione/esperienza precedente, organizziamo differenti
                  classi di formazione per accertarci che ogni collaboratore
                  disponga dei requisiti indispensabili per gestire un utenza
                  spesso in età avanzata, con fragilità o in momenti
                  particolarmente delicati della vita (come per esempio una
                  patologia acuta o – più spesso – cronica). I corsi di
                  formazione toccano l’ambito comportamentale, psicologico,
                  primo soccorso, supporto al familiare caregiver. Su richiesta
                  dei Partner è possibile integrare formazioni ad hoc.
                </p>
              </div>
            </div>
          </Container>
        </section>
        <section>
          <Container>
            <Heading variant="h5" tw="text-primary-500 text-center">
              Monitoraggio
            </Heading>
            <Heading variant="h4" tw="text-center mb-16">
              CONTROLLIAMO COSTANTEMENTE LA QUALITÀ
            </Heading>
            <div tw="flex flex-wrap -mx-5">
              <div tw="w-full md:w-1/2 text-center p-5 mb-10 md:mb-0">
                <Heading variant="h6">Feedback utente</Heading>
                <BackgroundImage
                  tw="p-5 md:p-10 overflow-hidden rounded-md"
                  fluid={companions.comp4.childImageSharp.fluid}
                >
                  <div tw="p-5 md:p-10 bg-white rounded-md bg-opacity-60">
                    A conclusione di ogni servizio al cliente viene proposta una
                    breve intervista telefonica o via mail per monitorare il
                    grado di soddisfazione e registrare eventuali problemi
                    riscontrati o suggerimenti per integrare nuove funzioni.
                  </div>
                </BackgroundImage>
              </div>
              <div tw="w-full md:w-1/2 text-center p-5">
                <Heading variant="h6">Feedback accompagnatore</Heading>
                <BackgroundImage
                  tw="p-5 md:p-10 overflow-hidden rounded-md"
                  fluid={companions.comp5.childImageSharp.fluid}
                >
                  <div tw="p-5 md:p-10 bg-white rounded-md bg-opacity-60">
                    Anche i nostri accompagnatori sono una fonte importantissima
                    di informazioni e idee nuove. Lasciano il proprio feedback e
                    segnalano ogni spunto per arricchire l’offerta all’utente e
                    formare al meglio gli UGO che verranno.
                  </div>
                </BackgroundImage>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default CompanionsPage;
